import React from 'react'
import BreadCrumb from '../components/BreadCrumb'
import Meta from '../components/Meta'
import { AiOutlineHome, AiOutlinePhone, AiOutlineMail, AiOutlineInfoCircle } from "react-icons/ai"
import Container from '../components/Container'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux'
import { createQuery } from '../features/contact/contactSlice'

const regexPhoneNumber = /([0-9]{9})\b/g;

let contactSchema = yup.object({
   name: yup.string().required('Name is required'),
   email: yup.string().email('Email is not valid').required('Email is required'),
   mobile: yup.string().required('Mobile Number is required').matches(regexPhoneNumber, 'Mobile Number is not valid'),
   comment: yup.string().required('Comment is required'),
});

const Contact = () => {
   const dispatch = useDispatch();
   const formik = useFormik({
      initialValues: {
         name: '',
         email: '',
         mobile: '',
         comment: '',
      },
      validationSchema: contactSchema,
      onSubmit: (values) => {
         dispatch(createQuery(values))
         formik.resetForm();
      },
   });

   return (
      <>
         <Meta title={"Contact Us"} />
         <BreadCrumb title="Contactez-Nous" />
         <Container class1="contact-wrapper py-5 home-wrapper-2">
            <div className="row">
           

               <div className="col-12">
                  <div className="contact-inner-wrapper d-flex justify-content-between">
                     <div>
                        <h3 className="contact-title mb-4">Contact</h3>
                        <form action="" onSubmit={formik.handleSubmit} className='d-flex flex-column gap-15'>
                           <div>
                              <input type="text"
                                 className="form-control"
                                 placeholder='Nom complet'
                                 name='name'
                                 onChange={formik.handleChange('name')}
                                 onBlur={formik.handleBlur('name')}
                                 value={formik.values.name}
                              />
                              <div className="error">
                                 {
                                    formik.touched.name && formik.errors.name
                                 }
                              </div>
                           </div>
                           <div>
                              <input type="email"
                                 className="form-control"
                                 placeholder='Email'
                                 name='email'
                                 onChange={formik.handleChange('email')}
                                 onBlur={formik.handleBlur('email')}
                                 value={formik.values.email}
                              />
                              <div className="error">
                                 {
                                    formik.touched.email && formik.errors.email
                                 }
                              </div>
                           </div>
                           <div>
                              <input type="tel"
                                 className="form-control"
                                 placeholder='Numéro de téléphone'
                                 name='mobile'
                                 onChange={formik.handleChange('mobile')}
                                 onBlur={formik.handleBlur('mobile')}
                                 value={formik.values.mobile}
                              />
                              <div className="error">
                                 {
                                    formik.touched.mobile && formik.errors.mobile
                                 }
                              </div>
                           </div>
                           <div>
                              <textarea id=""
                                 className='w-100 form-control' cols="30" rows="4"
                                 placeholder='Commentaire'
                                 name='comment'
                                 onChange={formik.handleChange('comment')}
                                 onBlur={formik.handleBlur('comment')}
                                 value={formik.values.comment}
                              ></textarea>
                              <div className="error">
                                 {
                                    formik.touched.comment && formik.errors.comment
                                 }
                              </div>
                           </div>
                           <div>
                              <button className="button border-0">Envoyer</button>
                           </div>
                        </form>
                     </div>
                     <div>
                        <h3 className="contact-title mb-4">Entrez en contact avec nous</h3>
                        <div>
                           <ul className='ps-0'>
                              <li className='mb-3 d-flex gap-15 align-items-center'>
                                 <AiOutlineHome className='fs-5' />
                                 <address className='mb-0'>Asian Co, Absa Cassé, Dakar</address>
                              </li>
                              <li className='mb-3 d-flex gap-15 align-items-center'>
                                 <AiOutlinePhone className='fs-5' />
                                 <a href="tel: +221 77 888 40 66">+221 77 888 40 66</a>
                              </li>
                              <li className='mb-3 d-flex gap-15 align-items-center'>
                                 <AiOutlineMail className='fs-5' />
                                 <a href="mailto: kcabsa@gmail.com">kcabsa@gmail.com</a>
                              </li>
                              <li className='mb-3 d-flex gap-15 align-items-center'>
                                 <AiOutlineInfoCircle className='fs-5' />
                                 <p className='mb-0'>Lundi – Samedi 10H - 18H</p>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </Container>
      </>
   )
}

export default Contact